@if (backgroundSound$ | async; as backgroundSound) {
  <audio loop #backgroundSound>
    <source [src]="backgroundSound.url"/>
  </audio>
}
@if (spinSound$ | async; as spinSound) {
  <audio #spinSound>
    <source [src]="spinSound.url"/>
  </audio>
}
<router-outlet></router-outlet>
