import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loggedInAuthGuard } from './guards/logged-in.guard';
import { mobileRedirectGuard } from './guards/mobile-redirect.guard';

const routes: Routes = [
  {
    path: 'd',
    loadChildren: () =>
      import('./features/desktop/desktop.module').then((m) => m.DesktopModule),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'm',
    loadChildren: () =>
      import('./features/mobile/mobile.module').then((m) => m.MobileModule),
    canActivate: [loggedInAuthGuard],
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./features/info-content/info-content.module').then(
        (m) => m.InfoContentModule,
      ),
  },
  {
    path: 'lottie',
    loadChildren: () =>
      import('./features/lottietest/lottietest.module').then(
        (m) => m.LottietestModule,
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./features/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./features/error/error.module').then((m) => m.ErrorModule),
    canActivate: [mobileRedirectGuard],
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
