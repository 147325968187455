import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(
    public http: HttpClient,
    public session: SessionService,
  ) {}

  initializeSession() {
    const { token, instance, gameUuid, lottie } = this.getQueryParams();
    this.session.thirdPartyToken = token;
    this.session.instance = instance;
    this.session.gameUuid = gameUuid;
    this.session.useLottie = lottie;
  }

  async buildEnvironment() {
    await this.preloadStaticAssets();
  }

  private async preloadStaticAssets() {
    // this.session.spinMachineLottie = await this.http.get<LottieFile>('/assets/animations/machine.json').toPromise();
  }

  private getQueryParams() {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    return {
      token: queryParams.get('token') ?? '',
      instance: queryParams.get('instance') ?? '',
      gameUuid: queryParams.get('gameUuid') ?? '',
      lottie: queryParams.get('lottie') === 'true',
    };
  }
}
