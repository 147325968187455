import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EnvironmentService } from '@services/environment.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import { SharedModule } from '@shared/shared.module';
import { WindowWithUser } from '@longnecktech/splash-commons-fe';
import { SessionService } from '@services/session.service';
import { GameService } from '@services/game.service';
import { firstValueFrom } from 'rxjs';

export function startupServiceFactory(injector: Injector) {
  return async () => {
    const envService = injector.get(EnvironmentService);
    const session = injector.get(SessionService);
    const gameService = injector.get(GameService);
    session.setUser((window as WindowWithUser).user);
    envService.initializeSession();
    // it's important to load the game before the all pages because
    // there is a theme in the response we need for translation and other things.
    await firstValueFrom(gameService.fetchGame(true));
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
