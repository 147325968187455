import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { SessionService } from '@services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private session: SessionService) {}

  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.session.user$.pipe(
      switchMap((user) => {
        if (user) {
          const headers = {
            Authorization: 'Bearer ' + user!.token,
          };
          httpRequest = httpRequest.clone({
            setHeaders: headers,
          });
        }
        return next.handle(httpRequest);
      }),
    );
  }
}
