import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { WindowWithUser } from '@longnecktech/splash-commons-fe';
const url = new URL(window.location.href);

function getQueryParams() {
  const queryParams = new URLSearchParams(url.search);
  return {
    token: queryParams.get('token') ?? '',
  };
}

const { token } = getQueryParams();

if (token) {
  fetch(`${environment.backendUrl}/api/public/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  })
    .then((response) => response.json())
    .then((data) => {
      (window as WindowWithUser).user = data;
      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
    })
    .catch((error) => console.error('Authentication error:', error));
} else {
  console.warn('No token!');
}
