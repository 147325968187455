import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { SessionService } from './session.service';
import { Game } from '@shared/types/game';
import { Theme } from '@shared/types/theme';
import { tap, take } from 'rxjs/operators';
import { SpinService } from './spin.service';
import { ConfigValue } from '../shared/types/theme';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  constructor(
    public readonly http: HttpClient,
    public readonly spinService: SpinService,
    public readonly session: SessionService
  ) {}

  public fetchGame(withSpinInfo = false) {
    return this.http
      .get<Game>(
        `${environment.backendUrl}/api/spinmachine/game?${this.getQueryParams()}`
      )
      .pipe(
        tap({
          next: data => {
            this.session.setGame(data);
            this.injectCSSVariablesIntoDOM(data.theme);
            if (withSpinInfo) {
              this.spinService
                .fetchSpinInfo(data)
                .pipe(take(1))
                .subscribe({
                  next: nextSpinInfo => {
                    if (nextSpinInfo.nextSpinInSeconds > 0) {
                      this.spinService.updateShowNextSpinInfo(true);
                    }
                  },
                });
            }
          },
          error: () => {
            // TODO: display no game found
          },
        })
      );
  }

  private hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }

  private injectCSSVariablesIntoDOM(theme: Theme) {
    const styleEl = document.createElement('style');
    const fonts = `
      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontRegularAsset?.url
            ? theme.primaryFontRegularAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf'
        }') format('truetype');
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontBoldAsset?.url
            ? theme.primaryFontBoldAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Bold.ttf'
        }') format('truetype');
        font-weight: 700;
      }

      @font-face {
        font-family: PrimaryFont;
        src: url('${
          theme.primaryFontMediumAsset?.url
            ? theme.primaryFontMediumAsset.url
            : '/assets/fonts/Montserrat/static/Montserrat-Medium.ttf'
        }') format('truetype');
        font-weight: 500;
      }
    `;

    styleEl.textContent = `:root {
      ${theme.cssVariables
        .filter(v => v.key.includes('--') && v.value)
        .map((v: ConfigValue) => {
          const originalValue = v.value;
          let rgbValue;

          if (originalValue.startsWith('rgba')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = `${match[0]}, ${match[1]}, ${match[2]}`;
            }
          } else if (originalValue.startsWith('rgb')) {
            const match = originalValue.match(/\d+/g);
            if (match) {
              rgbValue = match.join(', ');
            }
          } else if (originalValue.startsWith('#')) {
            rgbValue = this.hexToRgb(originalValue);
          }

          return `
          ${v.key}: ${originalValue};
          ${v.key}-rgb: ${rgbValue};
        `;
        })
        .join('\n')}
    }
    ${fonts}
    `;
    document.head.appendChild(styleEl);
  }

  private getQueryParams(): string {
    const params: { instance?: string; gameUuid?: string } = {};
    if (this.session.instance) params['instance'] = this.session.instance;
    if (this.session.gameUuid) params['gameUuid'] = this.session.gameUuid;
    return new URLSearchParams(params).toString();
  }
}
