import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { SessionService } from '@services/session.service';
import { map } from 'rxjs/operators';

export const loggedInAuthGuard: CanActivateFn = () => {
  const session = inject(SessionService);
  return session.user$.pipe(
    map((user) => {
      if (user) {
        return true;
      } else {
        inject(Router).navigateByUrl('/error');
        return false;
      }
    }),
  );
};
